// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-bordencontact-js": () => import("./../../../src/pages/bordencontact.js" /* webpackChunkName: "component---src-pages-bordencontact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infocollect-js": () => import("./../../../src/pages/infocollect.js" /* webpackChunkName: "component---src-pages-infocollect-js" */),
  "component---src-pages-newhires-js": () => import("./../../../src/pages/newhires.js" /* webpackChunkName: "component---src-pages-newhires-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-upcoming-events-js": () => import("./../../../src/pages/upcoming-events.js" /* webpackChunkName: "component---src-pages-upcoming-events-js" */),
  "component---src-template-borden-home-js": () => import("./../../../src/template/bordenHome.js" /* webpackChunkName: "component---src-template-borden-home-js" */),
  "component---src-template-event-ancmt-js": () => import("./../../../src/template/eventAncmt.js" /* webpackChunkName: "component---src-template-event-ancmt-js" */),
  "component---src-template-public-js": () => import("./../../../src/template/public.js" /* webpackChunkName: "component---src-template-public-js" */)
}

